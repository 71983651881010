@import '/projects/loki-desktop/src/vars';

input, .input, textarea {
  height: 36px; width: 100%;
  padding: 0 $spacing-small;
  font-family: $font; font-size: $font-size-p; line-height: $line-height-p; color: $text;
  background: $white;
  border: $border; border-radius: $rounded;
  overflow: hidden;
  transition: all .4s;

  &:disabled, &.disabled {
    color: rgba($text-dark, .2);
    background: $gray-light;
    border: $border-disabled;
    transition: all .2s;
  }
}

textarea {
  display: block;
  padding: $spacing-extra-small $spacing-small;
  height: auto; overflow-y: auto;
  resize: none;
}

.input-transparent {border: 0; padding: 0; background: transparent;}
.input-error {border: $border-alert;}

.input-icon {
  display: flex; align-items: center;
  padding: 0;

  input, .input {border: none;}

  span.icon, svg-icon-sprite {
    width: 36px;
    background-color: $gray-light;
    border-right: $border;
  }
  svg-icon-sprite {height: auto; padding: 6px;}
  span.icon {height: 100%;}

  &:disabled, &.disabled {
    span.icon, svg-icon-sprite {
      .icon { opacity: .2;}
      border-right: $border-disabled;
    }
  }
}

.input-small {max-width: 240px;}
.input-regular {max-width: 384px;}
.input-wide {max-width: 720px;}

input, input[type=text], input[type=password] {
  -webkit-appearance: none; /* IOS Safari */
  -moz-appearance: none;
  appearance: none;
}

.app-input-number {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {-webkit-appearance: none;}

  padding-left: 32px;

  // some random numbers to make it perfect
  & ~ svg-icon-sprite {
    position: absolute;
    top: auto; left: 0;
    width: 32px; height: 18px;

    .icon {
      width: 10px; height: 6px;
      margin: 8px 11px 0 11px;
    }
    &:last-of-type {
      bottom: 0;
      .icon {margin: 4px 11px 0 11px;}
    }
  }
}

@media screen and (max-width: $laptop) {
  input, .input {
    height: 30px;
    font-size: $font-size-p-responsive; line-height: $line-height-p-responsive;
  }

  .app-input-number ~ svg-icon-sprite {
    height: 15px;

    .icon {margin: 6px 11px 0 11px;}
    &:last-of-type .icon {margin: 3px 11px 0 11px;}
  }

  .input-icon {
    span.icon, svg-icon-sprite {width: 30px;}
    svg-icon-sprite {padding: 2px;}
  }
}

@media screen and (max-width: $mobile) {
  input, .input {height: 40px;}

  .app-input-number ~ svg-icon-sprite {
    height: 20px;

    .icon {margin: 10px 11px 0 11px;}
    &:last-of-type .icon {margin: 4px 11px 0 11px;}
  }

  .input-icon {
    span.icon, svg-icon-sprite {width: 40px;}
    svg-icon-sprite {padding: 8px;}
  }
}

@mixin placeholder($elem: '') {
  #{unquote($elem)}::-webkit-input-placeholder {@content} /* WebKit, Blink, Edge */
  #{unquote($elem)}:-moz-placeholder {@content} /* Mozilla Firefox 4-18 */
  #{unquote($elem)}::-moz-placeholder {@content} /* Mozilla Firefox 19+ */
  #{unquote($elem)}:-ms-input-placeholder {@content} /* Internet Explorer 10-11 */
  #{unquote($elem)}::-ms-input-placeholder {@content} /* Microsoft Edge */
  #{unquote($elem)}::placeholder {@content}
}

@include placeholder() {
  font-family: $font; font-size: $font-size-placeholder; color: rgba($text, .8); font-weight: $font-weight-extra-light;
}

@include placeholder('input:disabled') {
  opacity: .2;
}

@media screen and (max-width: $laptop) {
  @include placeholder() {
    font-size: $font-size-placeholder-responsive;
  }
}

input:-webkit-autofill, /* WebKit */
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset;
}
input { /* Mozilla Firefox */
  filter: none;
}
