@import '/projects/loki-desktop/src/vars';

.option {
  display: flex; align-items: center; height: 36px;
  padding: 0 $spacing-small;
  background: $white;
  cursor: pointer;

  opacity: 1;

  &:hover {background: $gray;}
  &-active {background: $gray;}
  &:not(:last-child) {border-bottom: 1px solid $gray-darker;}

  &:first-child {
    border-top-left-radius: $rounded;
    border-top-right-radius: $rounded;
  }
  &:last-child {
    border-bottom-left-radius: $rounded;
    border-bottom-right-radius: $rounded;
  }

  &:disabled, &.disabled {
    color: rgba($text-dark, .2);
    background: $gray-light;
    border: $border-disabled;
    transition: all .2s;
  }
}

@media screen and (max-width: $laptop) {
  .option {height: 30px;}
}

@media screen and (max-width: $mobile) {
  .option {height: 40px;}
}
