@import '/projects/loki-desktop/src/vars';

button, .button {
  min-height: 36px;
  padding: 0 $spacing-regular;
  font-size: $font-size-p; line-height: $line-height-p; font-family: $font; color: $text-dark;
  background: $white;
  border: $border; border-radius: $rounded;
  box-sizing: border-box;
  cursor: pointer;
  transition: all .4s;

  &:hover, &:focus, &:active, &.active {
    background: $gray;
    transition: all .2s;
  }
  &:disabled, &.disabled {
    color: rgba($text-dark, .2);
    background: $gray-light;
    border: $border-disabled;
    cursor: auto;
    transition: all .2s;

    .icon {opacity: .2;}
  }

  &::-moz-focus-inner {border: 0;}
}

a.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-gray {background: $gray-light;}
.button-green, .button-orange {
  color: $white;
  border: none;
}
.button-green {
  background: $green;
  &:hover, &:focus, &:active, &.active {
    &:enabled {background: $green-dark;}
  }
}
.button-orange {
  background: $orange;
  &:hover, &:focus, &:active, &.active {
    &:enabled {background: $orange-dark;}
  }
}

.button-icon {
  display: flex; align-items: center;

  .icon {
    margin-right: $spacing-small;
    transition: all .4s;
  }
}

.button-mini {
  min-height: 22px;
  font-size: $font-size-tiny; line-height: $line-height-tiny;
}
.button-wide {min-width: 212px;}

@media screen and (max-width: $laptop) {
  button, .button {
    min-height: 30px;
    font-size: $font-size-p-responsive; line-height: $line-height-p-responsive;
  }
}

@media screen and (max-width: $mobile) {
  button, .button {min-height: 40px;}
}
